import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './permission'
Vue.config.productionTip = false;

/** 1、清除样式框架 **/
import 'normalize.css/normalize.css'


/** 2、饿了吗组件框架 **/
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/zh-CN'
Vue.use(ElementUI, { locale });


/** 3、清除样式框架 **/
import '@/@@@dj-framework/style/element/index.css';
import '@/@@@dj-framework/@index';
import {dj_isWechat} from "@/@@@dj-framework/libs/function/@common";
// import infiniteScroll from 'vue-infinite-scroll'
// Vue.use(infiniteScroll)
import domObserve from 'vue-dom-observe';
Vue.use(domObserve);
if (dj_isWechat() === true){
  let href = window.location.href;
  if (href.dj_containSubString('/article/info') ||
      href.dj_containSubString('paper/info')
  ){
    href = href.replace('://','://mobile.');
    window.location.href = href;
  }

}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
