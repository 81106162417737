<template>
  <div id="app" class="app">
    <router-view/>
  </div>
</template>
<script>
export default {}
</script>
<style lang="scss" scoped>
.app{
  width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  color: #303133;
  background-color: white;
  font-family: "PingFang SC";
  box-sizing: border-box;
  letter-spacing: 0.5px;
}
body{
}


</style>
